var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-3/5 w-full mt-0" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-0",
              attrs: { label: "Título" },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("select-cnh-category", {
              attrs: { label: "Cat. Pret." },
              model: {
                value: _vm.cnhCategory,
                callback: function ($$v) {
                  _vm.cnhCategory = $$v
                },
                expression: "cnhCategory",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { type: "date", label: "Data" },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3 w-full mt-0" },
          [
            _c("select-employee", {
              attrs: { label: "Diretor Geral", roles: ["director_general"] },
              model: {
                value: _vm.generalDirector,
                callback: function ($$v) {
                  _vm.generalDirector = $$v
                },
                expression: "generalDirector",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3 w-full mt-0" },
          [
            _c("select-employee", {
              attrs: {
                label: "Diretor de Ensido",
                roles: ["teaching_director"],
              },
              model: {
                value: _vm.teachingDirector,
                callback: function ($$v) {
                  _vm.teachingDirector = $$v
                },
                expression: "teachingDirector",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-2/3 w-full mt-0" },
          [
            _c("select-employee", {
              attrs: {
                label: "Instrutores",
                roles: [
                  "practical_theoretical_instructor",
                  "practical_instructor",
                ],
                multiple: true,
              },
              model: {
                value: _vm.employees,
                callback: function ($$v) {
                  _vm.employees = $$v
                },
                expression: "employees",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c("h5", [_vm._v("Campos a mostrar na listagem")]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/1 w-full mt-0" },
          [
            _c("checkbox-student-field", {
              model: {
                value: _vm.properties,
                callback: function ($$v) {
                  _vm.properties = $$v
                },
                expression: "properties",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                { staticClass: "ml-4", on: { click: _vm.fetchDocument } },
                [_vm._v("Gerar Documento")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }