var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.localActive,
            title: "Listagem prático Ciretran",
          },
          on: {
            "update:active": function ($event) {
              _vm.localActive = $event
            },
          },
        },
        [
          _c("document-ciretran-practical-list", {
            attrs: { students: _vm.students },
            on: { save: _vm.generate },
          }),
        ],
        1
      ),
      _c("popup-pdf", { ref: "popupPdf" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }