<template>
  <div>
  <div
        class="vx-row"
        v-for="(document, i) in properties"
        :key="i"
      >
        <div class="vx-col md:w-1/3 w-full" v-if="document.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="localValue"
                :vs-value="document.value1"
                class="mr-4 mb-2"
                >{{ document.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="localValue"
                :vs-value="document.value2"
                class="mr-4 mb-2"
                >{{ document.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="localValue"
                :vs-value="document.value3"
                class="mr-4 mb-2"
                >{{ document.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default () {
        return []
      }
    }
  },
  data () {
    return {
      properties: [
        {
          value1: 'cpf',
          text1: 'CPF / CNPJ',
          value2: 'extra_field',
          text2: 'Campo Extra',
          value3: 'state_nasc_id',
          text3: 'Estado de Nascimento'
        },
        {
          value1: 'fathers_name',
          text1: 'Nome do Pai',
          value2: 'current_category_cnh_id',
          text2: 'Categoria Atual ',
          value3: 'orgao_emissor_rg_uf_id',
          text3: 'Estado do RG'
        },
        {
          value1: 'renach_form_number',
          text1: 'N° Renach',
          value2: 'intended_category_cnh_id',
          text2: 'Cat. pret.',
          value3: 'education',
          text3: 'Grau de instrução'
        },
        {
          value1: 'orgao_emissor_rg',
          text1: 'Órgão emissor RG',
          value2: 'city_nasc_id',
          text2: 'Cidade de nascimento',
          value3: 'nationality',
          text3: 'Nacionalidade'
        },
        {
          value1: 'occupation',
          text1: 'Data de nascimento',
          value2: 'name',
          text2: 'Nome',
          value3: 'rg',
          text3: 'RG/IE'
        },
        {
          value1: 'email',
          text1: 'Email',
          value2: 'mothers_name',
          text2: 'Nome da mãe',
          value3: 'intended_services_id',
          text3: 'Serviços pret.'
        },
        {
          value1: 'phones',
          text1: 'Telefones',
          value2: 'neighborhood',
          text2: 'Bairro',
          value3: 'complement',
          text3: 'Complemento'
        },
        {
          value1: 'street',
          text1: 'Rua',
          value2: 'zip_code',
          text2: 'CEP',
          value3: 'state_id',
          text3: 'Estado'
        },
        {
          value1: 'city_id',
          text1: 'Cidade',
          value2: 'number',
          text2: 'Número',
          value3: 'firstWarning',
          text3: '1º Aviso'
        },
        {
          value1: 'deliveryDate',
          text1: 'Data de entrega',
          value2: 'observation',
          text2: 'Observação',
          value3: 'secondWarning',
          text3: '2º Aviso'
        }
      ]
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {}
}
</script>
