var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("list", {
        ref: "list",
        attrs: {
          model: _vm.model,
          "index-select": _vm.indexSelect,
          "allow-search": _vm.allowSearch,
          "name-select": _vm.nameSelect,
          print: _vm.print,
          "prop-edit": _vm.edit,
        },
        on: {
          selected: _vm.selected,
          "search-end": function ($event) {
            return _vm.$emit("search-end")
          },
          "grid-ready": function ($event) {
            return _vm.$emit("grid-ready")
          },
          saved: function ($event) {
            return _vm.search(false)
          },
        },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: { title: _vm.title, active: _vm.showDoc },
          on: {
            "update:active": function ($event) {
              _vm.showDoc = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }