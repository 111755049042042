<template>
  <div id="cfc-documents">
    <document-ciretran-practical-list-modal :active.sync="documentCiretranPracticalListModal" :students="students" />
    <popup-pdf ref="popupPdf" />
    <vs-popup
      class="popup80 noOverflow"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupShowPdf"
    >
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>
    <document-issuances-process-list-modal :active.sync="documentIssuancesProcessListModal" :students="students" />
    <!-- PROTOCOLO  DE SERVIÇO -->
    <vs-popup
      class="popup80"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupProtocolService"
    >
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Digite o motivo.</label>
          <vs-textarea
            class="mb-10"
            v-model="protocolService.reason"
            rows="15"
          />
        </div>
      </div>
      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupProtocolService = false"
              >Cancelar</vs-button
            >
            <vs-button class="ml-4" @click="showPdf(13)"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

<!-- Listagem entrega cnh -->
<vs-popup
      class="popup80"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupDeliveryCnh"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input class="w-full mt-0" label="Título" v-model="title" />
        </div>
         <div class="vx-col md:w-1/5 w-full mt-0">
          <select-user v-model="userForListCnh" />
        </div>
         <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input class="w-full mt-0" label="Lote" v-model="lot" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Modo</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="mode"
            :reduce="(option) => option.value"
            :clearable="false"
            :options="[
              { value: 'pdf_retrato', label: 'Retrato' },
              { value: 'pdf_paisagem', label: 'Paisagem' }
            ]"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
         <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Formato</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="listFormat"
            :reduce="(option) => option.value"
            :clearable="false"
            :options="[
              { value: 'PDF', label: 'PDF' }
            ]"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
         <div class="vx-col md:w-1/5 w-full mt-0">
          <select-cnh-category v-model="cnhCategory" label="Cat. Pret."/>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input
                class="w-full"
                type="date"
                v-model="date"
                label="Data"
              />
        </div>
      </div>


      <vs-divider></vs-divider>

      <h5>Campos a mostrar na listagem</h5>

      <vs-divider></vs-divider>

      <div
        class="vx-row"
        v-for="(document, i) in deliveryOfDocumentList"
        :key="i"
      >
        <div class="vx-col md:w-1/3 w-full" v-if="document.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="custom_field"
                :vs-value="document.value1"
                class="mr-4 mb-2"
                >{{ document.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="custom_field"
                :vs-value="document.value2"
                class="mr-4 mb-2"
                >{{ document.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="custom_field"
                :vs-value="document.value3"
                class="mr-4 mb-2"
                >{{ document.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupListingProtocol = false"
              >Cancelar</vs-button
            >
            <vs-button
              class="ml-4"
              @click="showPdf(51)"
              :disabled="!validateListingProtocol"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>


   <!-- LISTAGEM / PROTOCOLO -->
    <vs-popup
      class="popup80"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupListingProtocol"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mt-0">
          <vs-input class="w-full mt-0" label="Título" v-model="title" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Gerar</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="listing_protocol"
            :reduce="(option) => option.value"
            data-vv-as="Gerar"
            data-vv-scope="listingProtocol"
            v-validate.initial="'required'"
            name="listing_protocol"
            :clearable="false"
            :options="[
              { value: 'listing_protocol', label: 'Listagem e Protocolo' },
              { value: 'listing', label: 'Somente Listagem' },
              { value: 'protocol', label: 'Somente Protocolo' }
            ]"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('listing_protocol')"
            >{{ errors.first('listing_protocol') }}</span
          >
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Modo</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="mode"
            :reduce="(option) => option.value"
            :clearable="false"
            :options="[
              { value: 'pdf_retrato', label: 'Retrato' },
              { value: 'pdf_paisagem', label: 'Paisagem' }
            ]"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
         <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Formato</label>
          <v-select
            class="vue_select_drop_size_90"
            v-model="listFormat"
            :reduce="(option) => option.value"
            :clearable="false"
            :options="[
              { value: 'PDF', label: 'PDF' },
              { value: 'WORD', label: 'WORD' }
            ]"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <select-cnh-category v-model="cnhCategory" label="Cat. Pret."/>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input
                class="w-full"
                type="date"
                v-model="date"
                label="Data"
              />
        </div>
      </div>

      <vs-divider></vs-divider>

      <h5>Campos a mostrar na listagem</h5>

      <vs-divider></vs-divider>

      <div
        class="vx-row"
        v-for="(document, i) in deliveryOfDocumentList"
        :key="i"
      >
        <div class="vx-col md:w-1/3 w-full" v-if="document.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="custom_field"
                :vs-value="document.value1"
                class="mr-4 mb-2"
                >{{ document.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="custom_field"
                :vs-value="document.value2"
                class="mr-4 mb-2"
                >{{ document.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="custom_field"
                :vs-value="document.value3"
                class="mr-4 mb-2"
                >{{ document.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupListingProtocol = false"
              >Cancelar</vs-button
            >
            <vs-button
              class="ml-4"
              @click="showPdf(5)"
              :disabled="!validateListingProtocol"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="popup80"
      :fullscreen="false"
      title="Protocolo de Entrada de Processo"
      :active.sync="popupProtocolRegistrationProcess"
    >
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <!-- Pacotes agora chamamos de serviços e serviços do pacote são ítens -->
          <div class="vx-col md:w-full w-full mt-0">
            <label class="vs-input--label">Serviços</label>
            <v-select
              class="vue_select_drop_size_90"
              v-model="protocolRegistrationProcess.servicePackages"
              :reduce="(option) => option.value"
              data-vv-as="Serviços"
              data-vv-scope="protocolRegistrationProcess"
              v-validate.initial="'required'"
              name="services"
              :options="servicePackages"
              multiple
              placeholder="Selecione"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
            <span
              class="text-danger text-sm"
              v-show="errors.has('protocolRegistrationProcess.services')"
              >{{ errors.first('protocolRegistrationProcess.services') }}</span
            >
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <vs-input
            class="w-full mt-0"
            label="Informações Adicionais"
            v-model="protocolRegistrationProcess.info"
          />
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button
              color="secondary"
              class="ml-auto"
              @click="popupProtocolRegistrationProcess = false"
              >Cancelar</vs-button
            >
            <vs-button
              class="ml-4"
              @click="showPdf(2)"
              :disabled="!validateprotocolRegistrationProcess"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- MODELO DE DOCUMENTO -->
    <!-- <vs-popup :fullscreen="true" :title="newDocumentTemplatePopupTitleModel" :active.sync="popupDocumentTemplate">

      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <vs-input class="w-full mt-0" label="Título" v-model="documentTemplate.title" data-vv-as="Título" data-vv-scope="documentTemplate" v-validate.initial="'required'" name="title" />
          <span class="text-danger text-sm" v-show="errors.has('documentTemplate.title')">{{ errors.first('documentTemplate.title') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Cabeçalho</label>
          <v-select v-model="documentTemplate.print_header" :reduce="option => option.value" :clearable="false" :options="[{label: 'SIM', value: true}, {label: 'NÃO', value: false}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
      </div>

      <div class="vx-row mt-4">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <div>
            <ckeditor :editor="editor" v-model="documentTemplate.body" :config="editorConfig" @ready="onReady"></ckeditor>
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="mt-0 flex flex-wrap items-center justify-end">
                  <vs-button class="ml-auto mt-4" color="secondary" @click="popupdocumentTemplate = false">Cancelar</vs-button>
                  <vs-button class="ml-4 mt-4" @click="saveDocumentTemplate" :disabled="!validateDocumentFormModel">Salvar Modelo De Documento</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-divider position="left"><strong class="text-sm">Variáveis do Aluno</strong></vs-divider>
          <p v-for="(variable, i) in studentVariables" :key="`${i}_studentVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Endereço</strong></vs-divider>
          <p v-for="(variable, i) in addressVariables" :key="`${i}_addressVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Avalista</strong></vs-divider>
          <p v-for="(variable, i) in guarantorVariables" :key="`${i}_guarantorVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Locatário</strong></vs-divider>
          <p v-for="(variable, i) in tenantVariables" :key="`${i}_tenantVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Contrato</strong></vs-divider>
          <p v-for="(variable, i) in contractVariables" :key="`${i}_contractVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
            {{variable.label}}
          </p>
        </div>
      </div>
    </vs-popup> -->

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <div class="flex-grow">
          <div v-if="!accordionDocumentTemplate">
            <label class="vs-input--label">Aluno(s)</label>
            <div class="mt-0 flex flex-wrap items-center justify">
              <v-select
                class="w-90__"
                v-model="students"
                @search="debouncedGetSearchStudent"
                multiple
                :clearable="false"
                :filterable="true"
                :reduce="(option) => option.value"
                :options="studentOptions"
                placeholder="Digite CPF ou nome do aluno..."
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              >
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <feather-icon
                @click="clearSelectedStudents"
                title="Limpar seleção"
                icon="MinusCircleIcon"
                class="ml-auto inline-flex rounded-full"
                svgClasses="w-8 h-8 cursor-pointer hover:text-primary"
              ></feather-icon>
            </div>
          </div>
        </div>

        <vs-input
          class="
            sm:mr-4
            mr-0
            sm:w-auto
            w-full
            sm:order-normal
            order-3
            sm:mt-3
            mt-4
            ml-6
          "
          style="margin-top: 19px !important"
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Busca rápida por documento..."
        />
        <vx-tooltip
          :text="
            accordionDocumentTemplate
              ? 'Fechar Cadastro de Documento'
              : 'Cadastrar Documento'
          "
          position="top"
          class="ml-1 mb-4 sm:mt-8 md:mb-0"
          style="margin-top: 19px !important"
        >
          <feather-icon
            v-if="!accordionDocumentTemplate"
            @click="addDocumentTemplate"
            icon="FilePlusIcon"
            svgClasses="h-8 w-8 cursor-pointer hover:text-primary"
          ></feather-icon>
          <feather-icon
            v-else
            @click="accordionDocumentTemplate = false"
            icon="FileMinusIcon"
            svgClasses="h-8 w-8 cursor-pointer hover:text-primary"
          ></feather-icon>
        </vx-tooltip>
      </div>

      <div v-if="accordionDocumentTemplate" class="vx-row mt-0">
        <div class="vx-col md:w-full w-full mt-0">
          <div class="vx-row">
            <div class="vx-col md:w-3/4 w-full mt-0">
              <vs-input
                class="w-full mt-0"
                label="Título"
                v-model="documentTemplate.title"
                data-vv-as="Título"
                data-vv-scope="documentTemplate"
                v-validate.initial="'required'"
                name="title"
              />accordionDocumentTemplate
              <span
                class="text-danger text-sm"
                v-show="errors.has('documentTemplate.title')"
                >{{ errors.first('documentTemplate.title') }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Cabeçalho</label>
              <v-select
                v-model="documentTemplate.print_header"
                :reduce="(option) => option.value"
                :clearable="false"
                :options="[
                  { label: 'SIM', value: true },
                  { label: 'NÃO', value: false }
                ]"
                placeholder="Selecione"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              ></v-select>
            </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col md:w-3/4 w-full mt-0">
              <div>
                <ckeditor
                  :editor="editor"
                  v-model="documentTemplate.body"
                  :config="editorConfig"
                  @ready="onReady"
                ></ckeditor>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="mt-0 flex flex-wrap items-center justify-end">
                      <vs-button
                        class="ml-auto mt-4"
                        color="secondary"
                        @click="accordionDocumentTemplate = false"
                        >Cancelar</vs-button
                      >
                      <vs-button
                        class="ml-4 mt-4"
                        @click="saveDocumentTemplate"
                        :disabled="!validateDocumentFormModel"
                        >Salvar Modelo De Documento</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-divider position="left"
                ><strong class="text-sm">Variáveis do Aluno</strong></vs-divider
              >
              <p
                v-for="(variable, i) in studentVariables"
                :key="`${i}_studentVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{ variable.label }}</span>
                </vx-tooltip>
              </p>
              <vs-divider position="left"
                ><strong class="text-sm">Endereço</strong></vs-divider
              >
              <p
                v-for="(variable, i) in addressVariables"
                :key="`${i}_addressVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{ variable.label }}</span>
                </vx-tooltip>
              </p>
              <vs-divider position="left"
                ><strong class="text-sm">Avalista</strong></vs-divider
              >
              <p
                v-for="(variable, i) in guarantorVariables"
                :key="`${i}_guarantorVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{ variable.label }}</span>
                </vx-tooltip>
              </p>
              <vs-divider position="left"
                ><strong class="text-sm">Locatário</strong></vs-divider
              >
              <p
                v-for="(variable, i) in tenantVariables"
                :key="`${i}_tenantVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{ variable.label }}</span>
                </vx-tooltip>
              </p>
              <vs-divider position="left"
                ><strong class="text-sm">Contrato</strong></vs-divider
              >
              <p
                v-for="(variable, i) in contractVariables"
                :key="`${i}_contractVariables`"
                class="text-sm cursor-pointer"
                @click="copyVariable(variable.variable)"
              >
                {{ variable.label }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <h3>Modelos</h3>
          <vs-table
            class="bordered"
            :data="preDocumentsFiltered"
            hoverFlat
            style="overflow: -webkit-paged-y"
          >
            <template slot="thead">
              <vs-th style="font-size: 11px"><span>TÍTULO</span></vs-th>
              <vs-th style="font-size: 11px"><span>AÇÃO</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                v-for="(doc, i) in data"
                :key="i"
                style="font-size: 12px; opacity: 1 !important"
              >
                <vs-td style="text-align: left"
                  ><span class="cursor-default font-semibold">{{
                    doc.title
                  }}</span></vs-td
                >
                <vs-td>
                  <div class="flex flex-wrap items-center">
                    <vx-tooltip text="Imprimir PDF" position="top" class="mt-2">
                      <feather-icon
                        @click="print(doc, 'PDF')"
                        title="Imprimir"
                        icon="PrinterIcon"
                        svgClasses="h-5 w-5 mr-2 hover:text-primary cursor-pointer"
                      />
                      <!-- <svg @click="print(doc, 'PDF')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="svg-hover h-5 w-5 cursor-pointer" aria-hidden="true" focusable="false" width="0.86em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1792"><path d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z" /></svg> -->
                    </vx-tooltip>
                    <vx-tooltip
                      v-if="doc.value === 5"
                      text="Gerar Excel"
                      position="top"
                      class="mt-2 ml-2"
                    >
                      <svg
                        @click="print(doc, 'EXCEL')"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="svg-hover h-5 w-5 cursor-pointer"
                        aria-hidden="true"
                        focusable="false"
                        width="1em"
                        height="1em"
                        style="
                          -ms-transform: rotate(360deg);
                          -webkit-transform: rotate(360deg);
                          transform: rotate(360deg);
                        "
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z"
                        />
                      </svg>
                    </vx-tooltip>
                  </div>

                  <!-- <svg @click="print(doc, 'WORD')" class="svg-hover h-6 w-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M282.208,19.67c-3.648-3.008-8.48-4.256-13.152-3.392l-256,48C5.472,65.686,0,72.278,0,79.99v352
                          c0,7.68,5.472,14.304,13.056,15.712l256,48c0.96,0.192,1.984,0.288,2.944,0.288c3.68,0,7.328-1.28,10.208-3.68
                          c3.68-3.04,5.792-7.584,5.792-12.32v-448C288,27.222,285.888,22.71,282.208,19.67z M256,460.694L32,418.71V93.27l224-41.984
                          V460.694z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M496,79.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h208v288H272c-8.832,0-16,7.168-16,16
                          c0,8.832,7.168,16,16,16h224c8.832,0,16-7.168,16-16v-320C512,87.158,504.832,79.99,496,79.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M432,143.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
                          C448,151.158,440.832,143.99,432,143.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M432,207.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
                          C448,215.158,440.832,207.99,432,207.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M432,271.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
                          C448,279.158,440.832,271.99,432,271.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M432,335.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
                          C448,343.158,440.832,335.99,432,335.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M209.76,176.086c-8.48-0.864-16.704,5.344-17.664,14.112l-8.608,77.504l-24.512-65.344
                          c-4.704-12.48-25.312-12.48-29.984,0l-26.016,69.408l-7.136-50.048c-1.248-8.768-9.44-14.976-18.112-13.568
                          c-8.736,1.248-14.816,9.344-13.568,18.08l16,112c1.024,7.264,6.848,12.896,14.112,13.664c7.424,0.736,14.144-3.424,16.704-10.272
                          L144,253.558l33.024,88.064c2.368,6.272,8.384,10.368,14.976,10.368c0.672,0,1.312-0.032,1.984-0.16
                          c7.328-0.896,13.088-6.752,13.92-14.08l16-144C224.864,184.982,218.56,177.078,209.76,176.086z"></path>
                      </g>
                    </g>
                    </svg> -->
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!-- HttpCommand - Reenviar commando -->
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <http-command-select
                @open-sigselect="openSigSelect"
                name-select="document"
                ref="httpComand"
              />
            </vs-col>
          </vs-row>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <h2>Documentos Criados</h2>
          <vs-table
            noDataText="Nenhum dado a ser mostrado."
            class="bordered"
            :data="documentsFiltered"
            hoverFlat
            style="overflow: -webkit-paged-y"
          >
            <template slot="thead">
              <vs-th style="font-size: 11px"><span>TÍTULO</span></vs-th>
              <vs-th style="font-size: 11px"><span>AÇÃO</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                v-for="(doc, i) in data"
                :key="i"
                style="font-size: 12px; opacity: 1 !important"
              >
                <vs-td style="text-align: left"
                  ><span class="cursor-default font-semibold">{{
                    doc.title
                  }}</span></vs-td
                >
                <vs-td class="flex flex-wrap items-center">
                  <feather-icon
                    @click="printTemplate(doc, 'PDF')"
                    title="Imprimir"
                    icon="PrinterIcon"
                    svgClasses="h-5 w-5 mr-2 hover:text-primary cursor-pointer"
                  />
                  <feather-icon
                    @click="update(doc)"
                    title="Alterar"
                    icon="EditIcon"
                    svgClasses="h-5 w-5 mr-2 hover:text-danger cursor-pointer"
                  />
                  <feather-icon
                    @click="confirmDelete(doc)"
                    title="Excluir"
                    icon="Trash2Icon"
                    svgClasses="h-5 w-5 mr-2 hover:text-danger cursor-pointer"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/axios'
import HttpCommandSelect from '@/components/httpCommand/HttpCommandSelect'
import DocumentCiretranPracticalListModal from '@/components/report/document/modal/DocumentCiretranPracticalListModal'
import modelHttpCommand from '@/store/httpCommand/model'
import SelectUser from '@/components/register/user/SelectUser.vue'
import DocumentIssuancesProcessListModal from '@/components/report/document/modal/DocumentIssuancesProcessListModal'
import SelectCnhCategory from '@/components/general/SelectCnhCategory.vue'
import PopupPdf from '@/components/report/pdf/PopupPdf.vue'

const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
// import moduleEmployee from '@/store/employee/moduleEmployee.js'
import moduleDocuments from '@/store/documents/moduleDocuments.js'
import moduleServicePackage from '@/store/service-package/moduleServicePackage.js'

import CKEditor from '@ckeditor/ckeditor5-vue2'

// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import ClassicEditor from '@/util/ckeditor5/src/ckeditor'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'

import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code.js'
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock.js'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js'
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport.js'
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js'
import Image from '@ckeditor/ckeditor5-image/src/image.js'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js'
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js'
import Link from '@ckeditor/ckeditor5-link/src/link.js'
import List from '@ckeditor/ckeditor5-list/src/list.js'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting.js'
import Table from '@ckeditor/ckeditor5-table/src/table.js'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage.js'
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js'
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js'
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js'
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js'
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters.js'
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows.js'
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency.js'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials.js'
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin.js'
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical.js'
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext.js'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption.js'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js'

export default {
  components: {
    vSelect,
    PopupPdf,
    ckeditor: CKEditor.component,
    HttpCommandSelect,
    SelectUser,
    DocumentCiretranPracticalListModal,
    SelectCnhCategory,
    DocumentIssuancesProcessListModal
  },

  data () {
    return {
      date: this.mixinToday(),
      cnhCategory: [],
      documentCiretranPracticalListModal: false,
      documentIssuancesProcessListModal: false,
      userForListCnh: {
        id: this.$user.id,
        name: this.$user.name
      },
      popupProtocolService: false,
      protocolService: {
        title: this.title,
        reason: null
      },
      popupListingProtocol: false,
      popupDeliveryCnh: false,
      listing_protocol: 'listing_protocol',
      mode: 'pdf_retrato',
      title: 'Protocolo',
      lot: 'LOTE ',
      format: 'PDF',
      listFormat: 'PDF',
      custom_field: [],
      deliveryOfDocumentList: [
        {
          value1: 'cpf',
          text1: 'CPF / CNPJ',
          value2: 'extra_field',
          text2: 'Campo Extra',
          value3: 'state_nasc_id',
          text3: 'Estado de Nascimento'
        },
        {
          value1: 'fathers_name',
          text1: 'Nome do Pai',
          value2: 'current_category_cnh_id',
          text2: 'Categoria Atual ',
          value3: 'orgao_emissor_rg_uf_id',
          text3: 'Estado do RG'
        },
        {
          value1: 'renach_form_number',
          text1: 'N° Renach',
          value2: 'intended_category_cnh_id',
          text2: 'Cat. pret.',
          value3: 'education',
          text3: 'Grau de instrução'
        },
        {
          value1: 'orgao_emissor_rg',
          text1: 'Órgão emissor RG',
          value2: 'city_nasc_id',
          text2: 'Cidade de nascimento',
          value3: 'nationality',
          text3: 'Nacionalidade'
        },
        {
          value1: 'occupation',
          text1: 'Data de nascimento',
          value2: 'name',
          text2: 'Nome',
          value3: 'rg',
          text3: 'RG/IE'
        },
        {
          value1: 'email',
          text1: 'Email',
          value2: 'mothers_name',
          text2: 'Nome da mãe',
          value3: 'intended_services_id',
          text3: 'Serviços pret.'
        },
        {
          value1: 'phones',
          text1: 'Telefones',
          value2: 'neighborhood',
          text2: 'Bairro',
          value3: 'complement',
          text3: 'Complemento'
        },
        {
          value1: 'street',
          text1: 'Rua',
          value2: 'zip_code',
          text2: 'CEP',
          value3: 'state_id',
          text3: 'Estado'
        },
        {
          value1: 'city_id',
          text1: 'Cidade',
          value2: 'number',
          text2: 'Número',
          value3: 'firstWarning',
          text3: '1º Aviso'
        },
        {
          value1: 'deliveryDate',
          text1: 'Data de entrega',
          value2: 'observation',
          text2: 'Observação',
          value3: 'secondWarning',
          text3: '2º Aviso'
        }
      ],

      popupProtocolRegistrationProcess: false,
      protocolRegistrationProcess: {
        servicePackages: [],
        info: null
      },

      newDocumentTemplatePopupTitleModel: null,
      documentTemplate: {
        id: null,
        title: null,
        print_header: false,
        body: ''
      },
      // popupDocumentTemplate: false,
      accordionDocumentTemplate: false,

      ckEd: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'pt-br',
        plugins: [
          Autoformat,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          GeneralHtmlSupport,
          Heading,
          HtmlEmbed,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          SourceEditing,
          Table,
          TableToolbar,
          TextTransformation,
          Alignment,
          AutoImage,
          CKFinderUploadAdapter,
          CloudServices,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Highlight,
          ImageResize,
          IndentBlock,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          TableCaption,
          TableCellProperties,
          TableProperties,
          Underline,
          WordCount
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            // 'link',
            // 'bulletedList',
            // 'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            // 'imageUpload',
            'blockQuote',
            'insertTable',
            // 'mediaEmbed',
            'undo',
            'redo',
            'alignment',
            'code',
            'findAndReplace',
            // 'fontBackgroundColor',
            // 'fontColor',
            'fontFamily',
            'fontSize',
            'highlight',
            'htmlEmbed',
            'removeFormat',
            'sourceEditing'
            // 'specialCharacters'
          ]
        },
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
            // 'tableCellProperties',
            // 'tableProperties'
          ]
        },
        htmlEmbed: {
          showPreviews: true
          // sanitizeHtml: (inputHtml) => {
          //   // Strip unsafe elements and attributes, e.g.:
          //   // the `<script>` elements and `on*` attributes.
          //   const outputHtml = sanitize(inputHtml)

          //   return {
          //     html: outputHtml,
          //     // true or false depending on whether the sanitizer stripped anything.
          //     hasChanged: true
          //   }
          // }
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            }
          ]
        }
      },

      // Variáveis do aluno
      studentVariables: [
        { variable: '[logo_empresa]', label: 'Logo da empresa' },
        { variable: '[dia]', label: 'Dia' },
        { variable: '[mes]', label: 'Mês' },
        { variable: '[ano]', label: 'Ano' },
        { variable: '[nome_aluno]', label: 'Nome' },
        { variable: '[nome_social]', label: 'Nome social' },
        { variable: '[CODIGO_ALUNO]', label: 'Código' },
        { variable: '[data_cadastro]', label: 'Data de cadastro' },
        { variable: '[renach]', label: 'Nº do formulário RENACH' },
        { variable: '[numero_cnh]', label: 'Número CNH' },
        { variable: '[cat_atual]', label: 'Categoria atual' },
        { variable: '[cat_pret]', label: 'Categoria pretendida' },
        { variable: '[data_nascimento]', label: 'Data de nascimento' },
        { variable: '[idade]', label: 'Idade' },
        { variable: '[nome_mae]', label: 'Nome da mãe' },
        { variable: '[nome_pai]', label: 'Nome do pai' },
        { variable: '[uf_nascimento]', label: 'Estado de nascimento' },
        { variable: '[cidade_nascimento]', label: 'Cidade de nascimento' },
        { variable: '[cpf_aluno]', label: 'CPF do aluno' },
        { variable: '[rg_aluno]', label: 'RG do aluno' },
        { variable: '[orgao_emissor]', label: 'Órgão emissor RG' },
        { variable: '[uf_rg]', label: 'Estado do RG' },
        { variable: '[nacionalidade_aluno]', label: 'Nacionalidade' },
        { variable: '[genero_aluno]', label: 'Gênero do aluno' },
        { variable: '[grau_instrucao]', label: 'Grau de instrução' },
        { variable: '[telefone_fixo]', label: 'Telefone fixo' },
        { variable: '[celular]', label: 'Celular' },
        { variable: '[email_aluno]', label: 'E-mail' },
        { variable: '[profissao_aluno]', label: 'Profissão' },
        { variable: '[estado_civil_aluno]', label: 'Estado civil' },
        { variable: '[extra_field]', label: 'Campo extra' },
        { variable: '[servicos_desejados]', label: 'Serviços Desejados' }
      ],

      // ENDEREÇO:
      addressVariables: [
        { variable: '[logo_empresa]', label: 'Logo da empresa' },
        { variable: '[estado_aluno]', label: 'Estado' },
        { variable: '[cidade_aluno]', label: 'Cidade' },
        { variable: '[rua_aluno]', label: 'Rua' },
        { variable: '[numero_endereco]', label: 'Número' },
        { variable: '[bairro_aluno]', label: 'Bairro' },
        { variable: '[cep_aluno]', label: 'CEP' },
        { variable: '[complemento_endereco]', label: 'Complemento' }
      ],

      // AVALISTA:
      guarantorVariables: [
        { variable: '[nome_avalista]', label: 'Nome avalista' },
        { variable: '[cpf_avalista]', label: 'CPF avalista' },
        { variable: '[rg_avalista]', label: 'RG avalista' },
        {
          variable: '[orgao_emissor_rg_avalista]',
          label: 'Órg. emissor RG avalista'
        },
        { variable: '[uf_rg_avalista]', label: 'UF RG avalista' },
        {
          variable: '[local_trabalho_avalista]',
          label: 'Local trabalho avalista'
        },
        { variable: '[conjuge_avalista]', label: 'Cônjuge avalista' },
        { variable: '[estado_avalista]', label: 'Estado' },
        { variable: '[cidade_avalista]', label: 'Cidade' },
        { variable: '[rua_avalista]', label: 'Rua' },
        { variable: '[numero_endereco_avalista]', label: 'Número end.' },
        { variable: '[bairro_avalista]', label: 'Bairro' },
        { variable: '[cep_avalista]', label: 'CEP' },
        {
          variable: '[complemento_endereco_avalista]',
          label: 'Complemento end.'
        }
      ],

      // LOCATÁRIO
      tenantVariables: [
        { variable: '[nome_locatario]', label: 'Nome Locatário' },
        { variable: '[cpf_locatario]', label: 'CPF Locatário' },
        { variable: '[rg_locatario]', label: 'RG Locatário' },
        {
          variable: '[orgao_emissor_rg_locatario]',
          label: 'Órg. emissor RG Locatário'
        },
        { variable: '[uf_rg_locatario]', label: 'UF RG Locatário' },
        { variable: '[estado_locatario]', label: 'Estado' },
        { variable: '[cidade_locatario]', label: 'Cidade' },
        { variable: '[rua_locatario]', label: 'Rua' },
        { variable: '[numero_endereco_locatario]', label: 'Número end.' },
        { variable: '[bairro_locatario]', label: 'Bairro' },
        { variable: '[cep_locatario]', label: 'CEP' }
      ],

      // CONTRATO:
      contractVariables: [
        { variable: '[CODIGO_CONTRATO]', label: 'Código' },
        { variable: '[valor_total]', label: 'Valor total do contrato' },
        { variable: '[valor_das_parcelas]', label: 'Valor de cada parcela' },
        {
          variable: '[valor_da_primeira_parcela]',
          label: 'Valor da primeira parcela/entrada'
        },
        { variable: '[numero_total_de_parcelas]', label: 'Número de parcelas' },
        {
          variable: '[vencimento_de_parcelas]',
          label: 'Vencimento de parcelas'
        },
        { variable: '[nome_do_servico]', label: 'Nome do serviço' },
        { variable: '[pacote_detalhado]', label: 'Pacote detalhado' },
        { variable: '[multa_do_contrato]', label: 'Multa do contrato' },
        { variable: '[juros_do_contrato]', label: 'Juros do contrato' }
      ],

      ///////////////////////////////

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      category2CNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' }
      ],

      students: [],
      studentOptions: [],
      searchQuery: '',
      preDocumentsFiltered: [],
      preDocuments: [
        { value: 6, title: 'Declaração de Exclusão' },
        { value: 7, title: 'Declaração de Desistência' },
        { value: 8, title: 'Declaração de Desistência Cat. A' },
        { value: 1, title: 'Ficha de Cadastro Com Dados Financeiros' },
        { value: '1B', title: 'Ficha de Cadastro Com Dados Financeiros MOD2' },
        { value: 5, title: 'Listagem / Protocolo 1' },
        { value: 5, title: 'Listagem / Protocolo 2' },
        { value: 15, title: 'Listagem Prático Ciretran' },
        { value: 16, title: 'Listagem processo emissão' },
        { value: 51, title: 'Listagem / Entrega CNH' },
        { value: 9, title: 'Modelo Padrão' },
        { value: 13, title: 'Protocolo de Atendimento' },
        { value: 14, title: 'Protocolo de Recebimento Ciretran' },
        { value: 2, title: 'Protocolo de Entrada de Processo' },
        { value: 3, title: 'Protocolo Para Cadastro CIRETRAN' },
        { value: 10, title: 'Reaver Categoria' },
        { value: 4, title: 'Recibo de Entrega Documentos' },
        { value: 11, title: 'Renovação Antecipada' },
        { value: 12, title: 'Transferência de Processo' }
      ]
    }
  },
  watch:{
    command (data) {
      this.$refs.httpComand.close()
      const me = this
      switch (true) {
      case data.url.includes('list/report/display'):
        this.custom_field = data.params.custom_field
        this.listing_protocol = data.params.listing_protocol
        this.mode = data.params.mode
        this.title = data.params.title
        this.format = data.params.format
        this.popupListingProtocol = true

        axios
          .post(`${process.env.VUE_APP_API_URL}/student/many`, data.params.students)
          .then(
            function (response) {
              response.data.forEach(e => {
                me.studentOptions.push({
                  value: e.id,
                  label: `${e.name} ${e.cpf || ''}`
                })
              })
              setTimeout(() => {
                me.students = data.params.students
              }, 1000)
            }.bind(this)
          )
          .catch(function (error) {
            console.error(error)
          })
        break
      }
    }
  },
  computed: {
    command () {
      return this.$store.state[modelHttpCommand.$store.name].command
    },
    user () {
      return this.$store.state.AppActiveUser
    },
    validateListingProtocol () {
      return !this.errors.any('listingProtocol')
    },
    validateprotocolRegistrationProcess () {
      return !this.errors.any('protocolRegistrationProcess')
    },
    validateDocumentFormModel () {
      return !this.errors.any('documentTemplate')
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    servicePackages () {
      return this.$store.getters['servicePackage/forSelect']
    },
    // employees () {
    //   return this.$store.getters['employee/forSelect']
    // },
    document () {
      return this.$store.state.documents.document
    },
    documentsFiltered () {
      return this.$store.state.documents.documentsFiltered
    },
    documents () {
      return this.$store.state.documents.documents
    }
  },
  methods: {
    openSigSelect () {
      this.$store.commit(modelHttpCommand.$store.commit.nameSelect, 'document')
    },
    htmlToNewWindow (html) {
      const win = window.open(
        '',
        'win',
        'width=800,height=400,screenX=200,screenY=200'
      )
      win.document.write(html)
    },
    addDocumentTemplate () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_student_documents'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('save'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.accordionDocumentTemplate = true

      // this.newDocumentTemplatePopupTitleModel = 'Novo Modelo de Documento'
      this.documentTemplate = {
        id: null,
        title: null,
        print_header: false,
        body: ''
      }
      // this.popupDocumentTemplate = true
    },
    async saveDocumentTemplate () {
      this.$vs.loading()
      try {
        if (this.documentTemplate.id) await this.$store.dispatch('documents/update', this.documentTemplate)
        else await this.$store.dispatch('documents/store', this.documentTemplate)
        // this.popupDocumentTemplate = false
        this.accordionDocumentTemplate = false
        this.$vs.loading.close()
        this.searchQuery = ''
        this.updateSearchQuery('')
        this.$vs.notify({
          time: 5000,
          title: 'Sucesso',
          text: 'Documento Salvo.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    onReady (editor) {
      this.ckEd = editor
      // console.log(editor.ui.componentFactory.names())
    },
    copyVariable (variable) {
      //INSERE A VARIÁVEL ONDE O CURSOR ESTIVER
      this.ckEd.model.change((writer) => {
        const insertPosition =
          this.ckEd.model.document.selection.getFirstPosition()
        writer.insertText(variable, {}, insertPosition)
      })

      //COPIA A VARIÁVEL PARA A MEMÓRIA (CTRL C + CTRL V)
      const thisIns = this
      this.$copyText(variable).then(
        function () {
          thisIns.$vs.notify({
            time: 700,
            title: 'Success',
            text: 'Variável copiada.',
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })
        },
        function () {
          thisIns.$vs.notify({
            title: 'Failed',
            text: 'Oops. Algo inesperado aconteceu. Por favor avise o suporte',
            color: 'danger',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-alert-circle'
          })
        }
      )
    },
    update (doc) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_student_documents'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('save'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$store.commit('documents/SET', doc)
      this.newDocumentTemplatePopupTitleModel = 'Alterar Modelo de Documento'
      this.documentTemplate = JSON.parse(JSON.stringify(this.document))
      this.accordionDocumentTemplate = true
      // this.popupDocumentTemplate = true
    },
    confirmDelete (doc) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_student_documents'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('delete'))
      ) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.$store.commit('documents/SET', doc)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este modelo de documento?',
        accept: this.delete,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    delete () {
      this.$store
        .dispatch('documents/delete', this.document.id)
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados Excluídos',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },
    async printTemplate (doc, type) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_student_documents'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('read'))
      ) {
        // ver ou emitir
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.students.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        if (type === 'PDF') {
          this.showPdfTitle = doc.title
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/document/template/student/pdf`,
            { doc, students: this.students }
          )
          this.srcPdf = `data:application/pdf;base64,${resp.data}`
          this.popupShowPdf = true
        }
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        this.$vs.loading.close()
        //
      }
    },
    async print (doc, type) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(
        (a) => a.module === 'spa_student_documents'
      )
      if (
        !this.user.roles.some((role) => role.name === 'admin') &&
        (!mod || !mod.pivot.permissions.includes('read'))
      ) {
        // ver ou emitir
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (
        !this.students.length &&
        (doc.value === '1B' ||
          doc.value === 1 ||
          doc.value === 2 ||
          doc.value === 3 ||
          doc.value === 4 ||
          doc.value === 5 ||
          doc.value === 6 ||
          doc.value === 7 ||
          doc.value === 8 ||
          doc.value === 9 ||
          doc.value === 10 ||
          doc.value === 11 ||
          doc.value === 12 ||
          doc.value === 13 ||
          doc.value === 15 ||
          doc.value === 16 ||
          doc.value === 51 ||
          doc.value === 14)
      ) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      try {
        this.$vs.loading()
        if (type === 'PDF') {
          if (doc.value === 1) {
            // ok
            this.showPdfTitle = 'Ficha de Cadastro Com Dados Financeiros'
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printRegistrationFinancialForm`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === '1B') {
            // ok
            this.showPdfTitle = 'Ficha de Cadastro Com Dados Financeiros MOD2'
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printRegistrationFinancialFormMod2`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 2) {
            // ok
            this.showPdfTitle = 'Protocolo de Entrada de Processo'
            this.popupProtocolRegistrationProcess = true
          } else if (doc.value === 3) {
            // ok
            this.showPdfTitle = 'Protocolo Para Cadastro CIRETRAN'
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/protocolRegistrationCiretran`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 4) {
            // ok
            this.showPdfTitle = 'Recibo de Entrega Documentos'
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/entregadocs`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 5) {
            // ok
            if (doc.title.includes(2)) {
              this.custom_field = ['name', 'cpf', 'rg', 'renach_form_number', 'intended_category_cnh_id', 'intended_services_id']
            }
            this.showPdfTitle = 'Listagem / Protocolo'

            this.popupListingProtocol = true
            this.format = type
          } else if (doc.value === 51) {
            // ok
            this.custom_field = ['name', 'cpf']
            this.showPdfTitle = 'Listagem / Entrega CNH'
            this.title = 'RETIRADA DE CNHs'
            this.popupDeliveryCnh = true
            this.format = type
          } else if (doc.value === 6) {
            // ok
            this.showPdfTitle = doc.title
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printExclusionStatement`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 7) {
            // ok
            this.showPdfTitle = doc.title
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printDeclarationOfWithdrawal`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 8) {
            // ok
            this.showPdfTitle = doc.title
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printDeclarationOfWithdrawalCatA`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 9) {
            // ok
            this.showPdfTitle = doc.title
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printDefaultModel`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 10) {
            // ok
            this.showPdfTitle = doc.title
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printRetrieveCategory`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 11) {
            // ok
            this.showPdfTitle = doc.title
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printEarlyRenewal`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 12) {
            // ok
            this.showPdfTitle = doc.title
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printProcessTransfer`,
              { student_id: this.students }
            )
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 13) {
            // ok
            this.showPdfTitle = this.title = doc.title
            this.popupProtocolService = true
            this.format = type
          } else if (doc.value === 14) {
            // ok
            this.showPdfTitle = doc.title
            const resp = await this.$http.post(
              `${process.env.VUE_APP_API_URL}/document/pdf/printReceiptProtocolCiretran`,
              { student_id: this.students }
            )
            this.htmlToNewWindow(resp.data)
          } else if (doc.value === 15) {
            // ok
            this.documentCiretranPracticalListModal = true
          } else if (doc.value === 16) {
            this.documentIssuancesProcessListModal = true
          }
        } else if (type === 'WORD') {
          const response = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/document/docx`,
            { student_id: this.students },
            { responseType: 'arraybuffer' }
          )
          const blob = new Blob([response.data], {
            type: 'application/octet-stream'
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'documento.docx'
          link.click()
        } else if (type === 'EXCEL') {
          if (doc.value === 5) {
            // ok
            this.showPdfTitle = 'Listagem / Protocolo'
            this.popupListingProtocol = true
            this.format = type
          }
        }
        if (!this.loading) {
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        }
        this.loading = false
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },

    async showPdf (id) {
      this.$vs.loading()
      if (id === 2) {
        //ok
        const resp = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/document/pdf/protocolRegistrationProcess`,
          {
            student_id: this.students,
            data: this.protocolRegistrationProcess
          }
        )
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
        this.popupProtocolRegistrationProcess = false
      } else if (id === 5) {
        if (this.listFormat === 'PDF') {
          this.popupListingProtocol = false
          this.loading = true
          this.$refs.popupPdf.post({
            url:  `${process.env.VUE_APP_API_URL}/list/report/display`,
            payload: {
              students: this.students,
              cnhCategory: this.cnhCategory,
              date: this.date,
              custom_field: this.custom_field,
              listing_protocol: this.listing_protocol,
              mode: this.mode,
              title: this.title,
              format: this.format,
              stream: true
            }
          })
        } else if (this.format === 'EXCEL') {
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/list/report/display`,
            {
              students: this.students,
              custom_field: this.custom_field,
              listing_protocol: this.listing_protocol,
              title: this.title,
              format: this.format
            },
            { responseType: 'arraybuffer' }
          )

          const blob = new Blob([resp.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          this.makeBlobDoc(blob, 'Relatório de Boletos')
        } else if (this.listFormat === 'WORD') {
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/list/report/display`,
            {
              students: this.students,
              custom_field: this.custom_field,
              cnhCategory: this.cnhCategory,
              date: this.date,
              listing_protocol: this.listing_protocol,
              mode: this.mode,
              title: this.title,
              format: this.listFormat,
              stream: true
            },
            { responseType: 'arraybuffer' }
          )
          const blob = new Blob([resp.data], {
            type: 'application/octet-stream'
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'documento.docx'
          link.click()
        }
        this.$nextTick(() => {
          this.popupListingProtocol = false
        })
      }  else if (id === 51) {
        if (this.listFormat === 'PDF') {
          this.popupListingProtocol = false
          this.loading = true
          this.$refs.popupPdf.post({
            url: `${process.env.VUE_APP_API_URL}/list/report/display`,
            payload: {
              students: this.students,
              custom_field: this.custom_field,
              cnhCategory: this.cnhCategory,
              date: this.date,
              user_name: this.userForListCnh.name,
              listing_protocol: 'listing_cnh',
              mode: this.mode,
              title: this.title,
              lot: this.lot,
              format: this.format,
              stream: true
            }
          })
        } else if (this.format === 'EXCEL') {
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/list/report/display`,
            {
              students: this.students,
              custom_field: this.custom_field,
              cnhCategory: this.cnhCategory,
              date: this.date,
              listing_protocol: this.listing_protocol,
              title: this.title,
              format: this.format
            },
            { responseType: 'arraybuffer' }
          )

          const blob = new Blob([resp.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          this.makeBlobDoc(blob, 'Relatório de Boletos')
        } else if (this.listFormat === 'WORD') {
          const resp = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/list/report/display`,
            {
              students: this.students,
              custom_field: this.custom_field,
              cnhCategory: this.cnhCategory,
              date: this.date,
              listing_protocol: this.listing_protocol,
              mode: this.mode,
              title: this.title,
              format: this.listFormat
            },
            { responseType: 'arraybuffer' }
          )
          const blob = new Blob([resp.data], {
            type: 'application/octet-stream'
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'documento.docx'
          link.click()
        }
        this.$nextTick(() => {
          this.popupListingProtocol = false
        })
      } else if (id === 13) {
        //ok
        const resp = await this.$http.post(
          `${process.env.VUE_APP_API_URL}/document/pdf/printProtocolService`,
          {
            student_id: this.students,
            data: this.protocolService
          }
        )
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
        this.popupProtocolService = false
      }
      if (!this.loading) this.$nextTick(() => {
        this.$vs.loading.close()
      })
      this.loading = false
    },
    clearSelectedStudents () {
      this.students.splice(0)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    getSearchResult (searchVal) {
      this.preDocumentsFiltered = JSON.parse(
        JSON.stringify(
          this.preDocuments.filter(
            (a) => a.title.toUpperCase().indexOf(searchVal.toUpperCase()) > -1
          )
        )
      )
      this.$store.commit('documents/FILTER_BY_TITLE', searchVal)
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http
          .post(`${process.env.VUE_APP_API_URL}/student/search`, {
            kw: search,
            type: 'STUDENT'
          })
          .then(
            function (response) {
              for (const k in response.data) {
                //INCLUIR SOMENTE OS ALUNOS QUE NÃO EXISTEM
                const idx = this.studentOptions.findIndex(
                  (e) => e.value === response.data[k].id
                )
                if (idx === -1) {
                  this.studentOptions.push({
                    value: response.data[k].id,
                    label: `${response.data[k].name} ${
                      response.data[k].cpf || ''
                    }`
                  })
                }
              }
              loading(false)
            }.bind(this)
          )
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    }
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)

    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }
    this.$store
      .dispatch('servicePackage/fetchAll')
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })
    // if (!moduleEmployee.isRegistered) {
    //   this.$store.registerModule('employee', moduleEmployee)
    //   moduleEmployee.isRegistered = true
    // }
    // this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleDocuments.isRegistered) {
      this.$store.registerModule('documents', moduleDocuments)
      moduleDocuments.isRegistered = true
    }
    this.$store
      .dispatch('documents/fetchAll')
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })
    this.preDocumentsFiltered = JSON.parse(JSON.stringify(this.preDocuments))
  }
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 650px;
}
.con-vs-popup .vs-popup {
  width: 100%;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup50 .vs-popup {
  width: 50% !important;
}
.vue_select_drop_size_90 .vs__dropdown-menu {
  max-height: 90px;
}
#cfc-documents th {
  background-color: #b4b4b4;
}
#cfc-documents th .vs-table-text {
  color: #158482;
  cursor: default;
}
#cfc-documents .vs-con-table .vs-con-tbody {
  border: 1px solid #c0c0c0;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#cfc-documents td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#cfc-documents td:hover {
  border-bottom: 1px solid #353434;
}
#cfc-documents td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#cfc-documents .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#cfc-documents .vs-table--thead {
  border: 1px solid transparent;
}
// .icon {
//   font-size: 16px;
// }
#cfc-documents
  .vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td {
  padding: 6px;
}
#cfc-documents .ps__scrollbar-y-rail {
  z-index: 99999;
}
.vs-tooltip {
  z-index: 99999;
}
.under:hover {
  text-decoration: underline;
}
.example-cfc-documents {
  // display: flex;
  // flex-direction: column;

  .editor {
    height: 70rem;
    overflow: hidden;
  }
  .ql-container {
    &.ql-snow {
      height: 92% !important;
      // border-top: none;
      // height: 24rem;
    }
  }
}
</style>
