var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-3/5 w-full mt-0" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-0",
              attrs: { label: "Título" },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("select-user", {
              model: {
                value: _vm.userForListCnh,
                callback: function ($$v) {
                  _vm.userForListCnh = $$v
                },
                expression: "userForListCnh",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { type: "date", label: "Data" },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c("h5", [_vm._v("Campos a mostrar na listagem")]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/1 w-full mt-0" },
          [
            _c("checkbox-student-field", {
              model: {
                value: _vm.properties,
                callback: function ($$v) {
                  _vm.properties = $$v
                },
                expression: "properties",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-checkbox",
                {
                  staticClass: "mr-4 mb-2",
                  model: {
                    value: _vm.signature,
                    callback: function ($$v) {
                      _vm.signature = $$v
                    },
                    expression: "signature",
                  },
                },
                [_vm._v("Assinatura")]
              ),
              _c(
                "vs-button",
                { staticClass: "ml-4", on: { click: _vm.fetchDocument } },
                [_vm._v("Gerar Documento")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }