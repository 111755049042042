var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.properties, function (document, i) {
      return _c("div", { key: i, staticClass: "vx-row" }, [
        document.value1
          ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
              _c("div", { staticClass: "mt-2" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap mt-1" },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        staticClass: "mr-4 mb-2",
                        attrs: { "vs-value": document.value1 },
                        model: {
                          value: _vm.localValue,
                          callback: function ($$v) {
                            _vm.localValue = $$v
                          },
                          expression: "localValue",
                        },
                      },
                      [_vm._v(_vm._s(document.text1))]
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        document.value2
          ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
              _c("div", { staticClass: "mt-2" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap mt-1" },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        staticClass: "mr-4 mb-2",
                        attrs: { "vs-value": document.value2 },
                        model: {
                          value: _vm.localValue,
                          callback: function ($$v) {
                            _vm.localValue = $$v
                          },
                          expression: "localValue",
                        },
                      },
                      [_vm._v(_vm._s(document.text2))]
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        document.value3
          ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
              _c("div", { staticClass: "mt-2" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap mt-1" },
                  [
                    _c(
                      "vs-checkbox",
                      {
                        staticClass: "mr-4 mb-2",
                        attrs: { "vs-value": document.value3 },
                        model: {
                          value: _vm.localValue,
                          callback: function ($$v) {
                            _vm.localValue = $$v
                          },
                          expression: "localValue",
                        },
                      },
                      [_vm._v(_vm._s(document.text3))]
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }