<template>
  <div>
    <div class="vx-row">
      <div class="vx-col md:w-3/5 w-full mt-0">
        <vs-input class="w-full mt-0" label="Título" v-model="title" />
      </div>
      <div class="vx-col md:w-1/5 w-full mt-0">
          <select-cnh-category v-model="cnhCategory" label="Cat. Pret."/>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input
                class="w-full"
                type="date"
                v-model="date"
                label="Data"
              />
        </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-1/3 w-full mt-0">
        <select-employee label="Diretor Geral" v-model="generalDirector" :roles="['director_general']" />
      </div>
       <div class="vx-col md:w-1/3 w-full mt-0">
        <select-employee label="Diretor de Ensido" v-model="teachingDirector" :roles="['teaching_director']" />
      </div>
       <div class="vx-col md:w-2/3 w-full mt-0">
        <select-employee label="Instrutores" v-model="employees" :roles="['practical_theoretical_instructor', 'practical_instructor']" :multiple="true"/>
      </div>
    </div>
    <vs-divider />
    <h5>Campos a mostrar na listagem</h5>
    <vs-divider />
    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full mt-0">
        <checkbox-student-field v-model="properties"/>
      </div>
    </div>
     <vs-divider />
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button class="ml-4"
            @click="fetchDocument"
              >Gerar Documento</vs-button
            >
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import CheckboxStudentField from '@/components/report/student/CheckboxStudentField'
import SelectEmployee from '@/components/register/employee/SelectEmployee'
import SelectCnhCategory from '@/components/general/SelectCnhCategory.vue'

export default {
  components: {
    CheckboxStudentField,
    SelectEmployee,
    SelectCnhCategory
  },
  props: {
    students: {}
  },
  data () {
    return {
      date: this.mixinToday(),
      cnhCategory: null,
      employees: [],
      title: 'LISTAGEM PRÁTICO CIRETRAN',
      generalDirector: null,
      teachingDirector: null,
      properties: ['name', 'cpf', 'intended_category_cnh_id', 'rg', 'intended_services_id', 'renach_form_number']
    }
  },
  methods: {
    fetchDocument () {
      this.$emit('save', {
        url: `${process.env.VUE_APP_API_URL}/list/report/ciretranPractical`,
        payload: {
          date: this.date,
          cnhCategory: this.cnhCategory,
          students: this.students,
          custom_field: this.properties,
          listing_protocol: 'ciretran_practical_list',
          title: this.title,
          employees: this.employees.map(e => e.name),
          generalDirector: this.generalDirector ? this.generalDirector.name : null,
          teachingDirector: this.teachingDirector ? this.teachingDirector.name : null,
        }
      })
    }
  }
}
</script>
