<template>
  <div>
    <div class="vx-row">
      <div class="vx-col md:w-3/5 w-full mt-0">
        <vs-input class="w-full mt-0" label="Título" v-model="title" />
      </div>
      <div class="vx-col md:w-1/5 w-full mt-0">
        <select-user v-model="userForListCnh" />
      </div>
      <div class="vx-col md:w-1/5 w-full mt-0">
        <vs-input class="w-full" type="date" v-model="date" label="Data" />
      </div>
    </div>
    <vs-divider />
    <h5>Campos a mostrar na listagem</h5>
    <vs-divider />
    <div class="vx-row">
      <div class="vx-col md:w-1/1 w-full mt-0">
        <checkbox-student-field v-model="properties" />
      </div>
    </div>
    <vs-divider />
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-checkbox v-model="signature"  class="mr-4 mb-2">Assinatura</vs-checkbox>
          <vs-button class="ml-4" @click="fetchDocument">Gerar Documento</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import CheckboxStudentField from '@/components/report/student/CheckboxStudentField'
import SelectCnhCategory from '@/components/general/SelectCnhCategory.vue'
import SelectUser from '@/components/register/user/SelectUser.vue'

export default {
  components: {
    SelectUser,
    CheckboxStudentField,
    SelectCnhCategory
  },
  props: {
    students: {}
  },
  data() {
    return {
      signature: false,
      date: this.mixinToday(),
      userForListCnh: {
        id: this.$user.id,
        name: this.$user.name
      },
      title: 'LISTAGEM PROCESSOS PARA EMISSÃO',
      properties: ['name', 'cpf', 'intended_services_id', 'renach_form_number']
    }
  },
  methods: {
    fetchDocument() {
      this.$emit('save', {
        url: `${process.env.VUE_APP_API_URL}/list/report/issuanceProcess`,
        payload: {
          date: this.date,
          students: this.students,
          signature: this.signature,
          custom_field: this.properties,
          listing_protocol: 'issuance_process_list',
          title: this.title,
          user_name: this.userForListCnh.name

        }
      })
    }
  }
}
</script>
  