<template>
  <div>
    <vs-popup :active.sync="localActive" title="Listagem prático Ciretran">
      <document-ciretran-practical-list @save="generate" :students="students" />
    </vs-popup>
    <popup-pdf ref="popupPdf" />
  </div>
</template>

<script>
import DocumentCiretranPracticalList from './../DocumentCiretranPracticalList'
import PopupPdf from '@/components/report/pdf/PopupPdf'
export default {
  components: {
    DocumentCiretranPracticalList,
    PopupPdf
  },
  props: {
    active: {},
    students: {}
  },
  data () {
    return {}
  },
  computed:{
    localActive: {
      get () {
        return this.active
      },
      set (value) {
        this.$emit('update:active', value)
      }
    }
  },
  methods: {
    generate (param) {
      this.localActive = false
      this.$refs.popupPdf.post(param)
    }
  }
}
</script>

