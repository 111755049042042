var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "cfc-documents" } },
    [
      _c("document-ciretran-practical-list-modal", {
        attrs: {
          active: _vm.documentCiretranPracticalListModal,
          students: _vm.students,
        },
        on: {
          "update:active": function ($event) {
            _vm.documentCiretranPracticalListModal = $event
          },
        },
      }),
      _c("popup-pdf", { ref: "popupPdf" }),
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c("document-issuances-process-list-modal", {
        attrs: {
          active: _vm.documentIssuancesProcessListModal,
          students: _vm.students,
        },
        on: {
          "update:active": function ($event) {
            _vm.documentIssuancesProcessListModal = $event
          },
        },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupProtocolService,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupProtocolService = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Digite o motivo."),
                ]),
                _c("vs-textarea", {
                  staticClass: "mb-10",
                  attrs: { rows: "15" },
                  model: {
                    value: _vm.protocolService.reason,
                    callback: function ($$v) {
                      _vm.$set(_vm.protocolService, "reason", $$v)
                    },
                    expression: "protocolService.reason",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupProtocolService = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.showPdf(13)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupDeliveryCnh,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupDeliveryCnh = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "Título" },
                  model: {
                    value: _vm.title,
                    callback: function ($$v) {
                      _vm.title = $$v
                    },
                    expression: "title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("select-user", {
                  model: {
                    value: _vm.userForListCnh,
                    callback: function ($$v) {
                      _vm.userForListCnh = $$v
                    },
                    expression: "userForListCnh",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "Lote" },
                  model: {
                    value: _vm.lot,
                    callback: function ($$v) {
                      _vm.lot = $$v
                    },
                    expression: "lot",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Modo"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { value: "pdf_retrato", label: "Retrato" },
                      { value: "pdf_paisagem", label: "Paisagem" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.mode,
                    callback: function ($$v) {
                      _vm.mode = $$v
                    },
                    expression: "mode",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Formato"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [{ value: "PDF", label: "PDF" }],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.listFormat,
                    callback: function ($$v) {
                      _vm.listFormat = $$v
                    },
                    expression: "listFormat",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("select-cnh-category", {
                  attrs: { label: "Cat. Pret." },
                  model: {
                    value: _vm.cnhCategory,
                    callback: function ($$v) {
                      _vm.cnhCategory = $$v
                    },
                    expression: "cnhCategory",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { type: "date", label: "Data" },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("h5", [_vm._v("Campos a mostrar na listagem")]),
          _c("vs-divider"),
          _vm._l(_vm.deliveryOfDocumentList, function (document, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              document.value1
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value1 },
                              model: {
                                value: _vm.custom_field,
                                callback: function ($$v) {
                                  _vm.custom_field = $$v
                                },
                                expression: "custom_field",
                              },
                            },
                            [_vm._v(_vm._s(document.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value2
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value2 },
                              model: {
                                value: _vm.custom_field,
                                callback: function ($$v) {
                                  _vm.custom_field = $$v
                                },
                                expression: "custom_field",
                              },
                            },
                            [_vm._v(_vm._s(document.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value3
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value3 },
                              model: {
                                value: _vm.custom_field,
                                callback: function ($$v) {
                                  _vm.custom_field = $$v
                                },
                                expression: "custom_field",
                              },
                            },
                            [_vm._v(_vm._s(document.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupListingProtocol = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: !_vm.validateListingProtocol },
                      on: {
                        click: function ($event) {
                          return _vm.showPdf(51)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupListingProtocol,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupListingProtocol = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "Título" },
                  model: {
                    value: _vm.title,
                    callback: function ($$v) {
                      _vm.title = $$v
                    },
                    expression: "title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Gerar"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    reduce: (option) => option.value,
                    "data-vv-as": "Gerar",
                    "data-vv-scope": "listingProtocol",
                    name: "listing_protocol",
                    clearable: false,
                    options: [
                      {
                        value: "listing_protocol",
                        label: "Listagem e Protocolo",
                      },
                      { value: "listing", label: "Somente Listagem" },
                      { value: "protocol", label: "Somente Protocolo" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.listing_protocol,
                    callback: function ($$v) {
                      _vm.listing_protocol = $$v
                    },
                    expression: "listing_protocol",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("listing_protocol"),
                        expression: "errors.has('listing_protocol')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("listing_protocol")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Modo"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { value: "pdf_retrato", label: "Retrato" },
                      { value: "pdf_paisagem", label: "Paisagem" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.mode,
                    callback: function ($$v) {
                      _vm.mode = $$v
                    },
                    expression: "mode",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Formato"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { value: "PDF", label: "PDF" },
                      { value: "WORD", label: "WORD" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [_vm._v(" Nenhum resultado encontrado. ")]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.listFormat,
                    callback: function ($$v) {
                      _vm.listFormat = $$v
                    },
                    expression: "listFormat",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("select-cnh-category", {
                  attrs: { label: "Cat. Pret." },
                  model: {
                    value: _vm.cnhCategory,
                    callback: function ($$v) {
                      _vm.cnhCategory = $$v
                    },
                    expression: "cnhCategory",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { type: "date", label: "Data" },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("h5", [_vm._v("Campos a mostrar na listagem")]),
          _c("vs-divider"),
          _vm._l(_vm.deliveryOfDocumentList, function (document, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              document.value1
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value1 },
                              model: {
                                value: _vm.custom_field,
                                callback: function ($$v) {
                                  _vm.custom_field = $$v
                                },
                                expression: "custom_field",
                              },
                            },
                            [_vm._v(_vm._s(document.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value2
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value2 },
                              model: {
                                value: _vm.custom_field,
                                callback: function ($$v) {
                                  _vm.custom_field = $$v
                                },
                                expression: "custom_field",
                              },
                            },
                            [_vm._v(_vm._s(document.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value3
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value3 },
                              model: {
                                value: _vm.custom_field,
                                callback: function ($$v) {
                                  _vm.custom_field = $$v
                                },
                                expression: "custom_field",
                              },
                            },
                            [_vm._v(_vm._s(document.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupListingProtocol = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: !_vm.validateListingProtocol },
                      on: {
                        click: function ($event) {
                          return _vm.showPdf(5)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: "Protocolo de Entrada de Processo",
            active: _vm.popupProtocolRegistrationProcess,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupProtocolRegistrationProcess = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-full w-full mt-0" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-full w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Serviços"),
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "vue_select_drop_size_90",
                    attrs: {
                      reduce: (option) => option.value,
                      "data-vv-as": "Serviços",
                      "data-vv-scope": "protocolRegistrationProcess",
                      name: "services",
                      options: _vm.servicePackages,
                      multiple: "",
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "no-options",
                        fn: function ({}) {
                          return [
                            _vm._v(
                              "\n                Nenhum resultado encontrado.\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.protocolRegistrationProcess.servicePackages,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.protocolRegistrationProcess,
                          "servicePackages",
                          $$v
                        )
                      },
                      expression: "protocolRegistrationProcess.servicePackages",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has(
                            "protocolRegistrationProcess.services"
                          ),
                          expression:
                            "errors.has('protocolRegistrationProcess.services')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.errors.first(
                            "protocolRegistrationProcess.services"
                          )
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "Informações Adicionais" },
                  model: {
                    value: _vm.protocolRegistrationProcess.info,
                    callback: function ($$v) {
                      _vm.$set(_vm.protocolRegistrationProcess, "info", $$v)
                    },
                    expression: "protocolRegistrationProcess.info",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupProtocolRegistrationProcess = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        disabled: !_vm.validateprotocolRegistrationProcess,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showPdf(2)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "vx-card p-6" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center" },
          [
            _c("div", { staticClass: "flex-grow" }, [
              !_vm.accordionDocumentTemplate
                ? _c("div", [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Aluno(s)"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "mt-0 flex flex-wrap items-center justify",
                      },
                      [
                        _c("v-select", {
                          staticClass: "w-90__",
                          attrs: {
                            multiple: "",
                            clearable: false,
                            filterable: true,
                            reduce: (option) => option.value,
                            options: _vm.studentOptions,
                            placeholder: "Digite CPF ou nome do aluno...",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          on: { search: _vm.debouncedGetSearchStudent },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                  Nenhum resultado encontrado.\n                "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4239893430
                          ),
                          model: {
                            value: _vm.students,
                            callback: function ($$v) {
                              _vm.students = $$v
                            },
                            expression: "students",
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "ml-auto inline-flex rounded-full",
                          attrs: {
                            title: "Limpar seleção",
                            icon: "MinusCircleIcon",
                            svgClasses:
                              "w-8 h-8 cursor-pointer hover:text-primary",
                          },
                          on: { click: _vm.clearSelectedStudents },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("vs-input", {
              staticClass:
                "sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-3 mt-4 ml-6",
              staticStyle: { "margin-top": "19px !important" },
              attrs: { placeholder: "Busca rápida por documento..." },
              on: { input: _vm.updateSearchQuery },
              model: {
                value: _vm.searchQuery,
                callback: function ($$v) {
                  _vm.searchQuery = $$v
                },
                expression: "searchQuery",
              },
            }),
            _c(
              "vx-tooltip",
              {
                staticClass: "ml-1 mb-4 sm:mt-8 md:mb-0",
                staticStyle: { "margin-top": "19px !important" },
                attrs: {
                  text: _vm.accordionDocumentTemplate
                    ? "Fechar Cadastro de Documento"
                    : "Cadastrar Documento",
                  position: "top",
                },
              },
              [
                !_vm.accordionDocumentTemplate
                  ? _c("feather-icon", {
                      attrs: {
                        icon: "FilePlusIcon",
                        svgClasses: "h-8 w-8 cursor-pointer hover:text-primary",
                      },
                      on: { click: _vm.addDocumentTemplate },
                    })
                  : _c("feather-icon", {
                      attrs: {
                        icon: "FileMinusIcon",
                        svgClasses: "h-8 w-8 cursor-pointer hover:text-primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.accordionDocumentTemplate = false
                        },
                      },
                    }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.accordionDocumentTemplate
          ? _c("div", { staticClass: "vx-row mt-0" }, [
              _c("div", { staticClass: "vx-col md:w-full w-full mt-0" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-3/4 w-full mt-0" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: "required",
                            expression: "'required'",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "w-full mt-0",
                        attrs: {
                          label: "Título",
                          "data-vv-as": "Título",
                          "data-vv-scope": "documentTemplate",
                          name: "title",
                        },
                        model: {
                          value: _vm.documentTemplate.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.documentTemplate, "title", $$v)
                          },
                          expression: "documentTemplate.title",
                        },
                      }),
                      _vm._v("accordionDocumentTemplate\n              "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("documentTemplate.title"),
                              expression:
                                "errors.has('documentTemplate.title')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.errors.first("documentTemplate.title"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Cabeçalho"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          reduce: (option) => option.value,
                          clearable: false,
                          options: [
                            { label: "SIM", value: true },
                            { label: "NÃO", value: false },
                          ],
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        model: {
                          value: _vm.documentTemplate.print_header,
                          callback: function ($$v) {
                            _vm.$set(_vm.documentTemplate, "print_header", $$v)
                          },
                          expression: "documentTemplate.print_header",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mt-4" }, [
                  _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-0" }, [
                    _c(
                      "div",
                      [
                        _c("ckeditor", {
                          attrs: {
                            editor: _vm.editor,
                            config: _vm.editorConfig,
                          },
                          on: { ready: _vm.onReady },
                          model: {
                            value: _vm.documentTemplate.body,
                            callback: function ($$v) {
                              _vm.$set(_vm.documentTemplate, "body", $$v)
                            },
                            expression: "documentTemplate.body",
                          },
                        }),
                        _c("div", { staticClass: "vx-row" }, [
                          _c("div", { staticClass: "vx-col w-full" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-0 flex flex-wrap items-center justify-end",
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "ml-auto mt-4",
                                    attrs: { color: "secondary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.accordionDocumentTemplate = false
                                      },
                                    },
                                  },
                                  [_vm._v("Cancelar")]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "ml-4 mt-4",
                                    attrs: {
                                      disabled: !_vm.validateDocumentFormModel,
                                    },
                                    on: { click: _vm.saveDocumentTemplate },
                                  },
                                  [_vm._v("Salvar Modelo De Documento")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                    [
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Variáveis do Aluno"),
                        ]),
                      ]),
                      _vm._l(_vm.studentVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_studentVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Endereço"),
                        ]),
                      ]),
                      _vm._l(_vm.addressVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_addressVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Avalista"),
                        ]),
                      ]),
                      _vm._l(_vm.guarantorVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_guarantorVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Locatário"),
                        ]),
                      ]),
                      _vm._l(_vm.tenantVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_tenantVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Contrato"),
                        ]),
                      ]),
                      _vm._l(_vm.contractVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_contractVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(variable.label) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "vx-row mt-4" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-0" },
            [
              _c("h3", [_vm._v("Modelos")]),
              _c(
                "vs-table",
                {
                  staticClass: "bordered",
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.preDocumentsFiltered, hoverFlat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (doc, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "1 !important",
                              },
                            },
                            [
                              _c(
                                "vs-td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-default font-semibold",
                                    },
                                    [_vm._v(_vm._s(doc.title))]
                                  ),
                                ]
                              ),
                              _c("vs-td", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex flex-wrap items-center",
                                  },
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          text: "Imprimir PDF",
                                          position: "top",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            title: "Imprimir",
                                            icon: "PrinterIcon",
                                            svgClasses:
                                              "h-5 w-5 mr-2 hover:text-primary cursor-pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.print(doc, "PDF")
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    doc.value === 5
                                      ? _c(
                                          "vx-tooltip",
                                          {
                                            staticClass: "mt-2 ml-2",
                                            attrs: {
                                              text: "Gerar Excel",
                                              position: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "svg-hover h-5 w-5 cursor-pointer",
                                                staticStyle: {
                                                  "-ms-transform":
                                                    "rotate(360deg)",
                                                  "-webkit-transform":
                                                    "rotate(360deg)",
                                                  transform: "rotate(360deg)",
                                                },
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  "xmlns:xlink":
                                                    "http://www.w3.org/1999/xlink",
                                                  "aria-hidden": "true",
                                                  focusable: "false",
                                                  width: "1em",
                                                  height: "1em",
                                                  preserveAspectRatio:
                                                    "xMidYMid meet",
                                                  viewBox: "0 0 24 24",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.print(
                                                      doc,
                                                      "EXCEL"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("TÍTULO")]),
                      ]),
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("AÇÃO")]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-type": "flex",
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-w": "12",
                      },
                    },
                    [
                      _c("http-command-select", {
                        ref: "httpComand",
                        attrs: { "name-select": "document" },
                        on: { "open-sigselect": _vm.openSigSelect },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-0" },
            [
              _c("h2", [_vm._v("Documentos Criados")]),
              _c(
                "vs-table",
                {
                  staticClass: "bordered",
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: {
                    noDataText: "Nenhum dado a ser mostrado.",
                    data: _vm.documentsFiltered,
                    hoverFlat: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (doc, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "1 !important",
                              },
                            },
                            [
                              _c(
                                "vs-td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-default font-semibold",
                                    },
                                    [_vm._v(_vm._s(doc.title))]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { staticClass: "flex flex-wrap items-center" },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      title: "Imprimir",
                                      icon: "PrinterIcon",
                                      svgClasses:
                                        "h-5 w-5 mr-2 hover:text-primary cursor-pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.printTemplate(doc, "PDF")
                                      },
                                    },
                                  }),
                                  _c("feather-icon", {
                                    attrs: {
                                      title: "Alterar",
                                      icon: "EditIcon",
                                      svgClasses:
                                        "h-5 w-5 mr-2 hover:text-danger cursor-pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.update(doc)
                                      },
                                    },
                                  }),
                                  _c("feather-icon", {
                                    attrs: {
                                      title: "Excluir",
                                      icon: "Trash2Icon",
                                      svgClasses:
                                        "h-5 w-5 mr-2 hover:text-danger cursor-pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmDelete(doc)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("TÍTULO")]),
                      ]),
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("AÇÃO")]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }